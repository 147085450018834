// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
// Variables
@import 'variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~jquery-toast-plugin/dist/jquery.toast.min.css';
@import '~pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.min.css';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

@import 'check-radio';

$primary-color: #144371;
$secondary-color: #144371;
$light-color: #1e293c;
$other-color: #666;
$other-text-color: #2CF5D1;

.bg-primary {
    background-color: $primary-color !important;
}

.border-primary {
    border-color: $primary-color !important;
}

.bg-secondary {
    background-color: $secondary-color !important;
}

.bg-other {
    background-color: $other-color !important;
}

.text-other {
    background-color: $other-text-color !important;
}

.border-secondary {
    border-color: $secondary-color !important;
}

.bg-light {
    background-color: $light-color !important;
}

.border-light {
    border-color: $light-color !important;
}

.border-1 {
    border-width: 1px;
}

.border-2 {
    border-width: 2px;
}

.border-3 {
    border-width: 3px;
}

.border-squared {
    border-radius: 0;
}

.border-dashed {
    border-style: dashed;
}

.border-solid {
    border-style: solid;
}

.border-dotted {
    border-style: dotted;
}

.btn {
    &.bg-primary {
        &:hover {
            background-color: $secondary-color !important;
        }
    }

    &.bg-secondary {
        &:hover {
            background-color: $primary-color !important;
        }
    }

    &.border-primary {
        &:hover {
            border-color: $secondary-color !important;
            color: $secondary-color !important;
        }
    }

    &.border-secondary {
        &:hover {
            border-color: $primary-color !important;
            color: $primary-color !important;
        }
    }
}

a {
    &.text-primary {
        &:hover {
            color: $secondary-color !important;
        }
    }

    &.text-secondary {
        &:hover {
            color: $primary-color !important;
        }
    }
}

.text-primary {
    color: $primary-color !important;
}

.text-secondary {
    color: $secondary-color !important;
}

.hover-bg-primary:hover {
    background-color: $primary-color !important;
}

.hover-bg-secondary:hover {
    background-color: $secondary-color !important;
}

//@font-face {
//    font-family: 'Freich';
//    src: url('../webfonts/TOMMYBold.otf') format('opentype');
//    font-weight: normal;
//    font-style: normal;
//}

//@font-face {
//    font-family: 'DINProLight';
//    src: url('../webfonts/DINLight.ttf') format('truetype');
//}
//
//@font-face {
//    font-family: 'DINProMedium';
//    src: url('../webfonts/DINMedium.ttf') format('truetype');
//}
//
//@font-face {
//    font-family: 'DINProBold';
//    src: url('../webfonts/DINBold.ttf') format('truetype');
//}
//
//@font-face {
//    font-family: 'DINProBlack';
//    src: url('../webfonts/DINBlackAlternate.ttf') format('truetype');
//}

.font-freich {
    font-family: Verdana;
}

.font-dinpro-light {
    font-family: Verdana;
}

.font-dinpro-medium {
    font-family: Verdana;
}

.font-dinpro-bold {
    font-family: Verdana;
}

.font-dinpro-black {
    font-family: Verdana;
}

//@import "~balloon-css/src/balloon";

//@import 'balloon';

@media (min-width: 992px) {
    .lg\:top-16 {
        margin-top: 10rem !important;
    }
}

html {
    height: 100%;
    font-family: sans-serif;
    font-size: 1.1em;
}

body {
    height: 100%;
    //overflow: hidden;
    margin: 0;
    padding: 0;
    //display: flex;
}

//.column {
//    height: 100%;
//    display: flex;
//    flex-direction: column;
//}

#left {
    flex-shrink: 0;
    background-color: white;
}

#right {
    background-color: #f3f3f3;
}

.top-left {
    flex-shrink: 0;
    background-color: #333;
    color: white;
    padding: 20px;
}

.top-right {
    display: inline-flex;
    flex-shrink: 0;
    background-color: #333;
    color: white;
    padding: 20px;
}

.bottom {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
}


@media (min-width: 1024px) {
    .right-wrapper {
        width: calc(100% - 16.666667%);
    }
}

.page-wrapper {
    height: calc(100% - 71px);
}

.editor-wrapper {
    height: calc(100% - 49px);

    textarea {
        overflow: hidden;
        overflow-wrap: break-word;
        resize: none;
        display: block;
        width: 100%;
        margin: 0;
        box-shadow: none;
    }
}

.has-row-details .table-details {
    display: none;
    position: static;
}

.has-row-details tbody tr:hover .table-details {
    display: block;
}

.vertical-top {
    vertical-align: top;
}

.vertical-middle {
    vertical-align: middle;
}

.breadcrumb {
    ol li:not(:last-child) {
        border-right: 1px solid #cbd5e0;
    }
}

//input[type='checkbox'], [type='radio'] {
//    display: none;
//}

//[type='checkbox'] + label::before {
//    content: "\2610";
//}
//
//[type='checkbox']:checked + label::before {
//    content: "\2611";
//}
//
//[type='radio'] + label::before {
//    content: "\2B58";
//}
//
//[type='radio']:checked + label::before {
//    content: "\25C9";
//}


//input[type=checkbox] + label::before {
//    content: "\F0C8";
//    box-sizing: border-box;
//    border-width: 0;
//    border-style: solid;
//    border-color: #e2e8f0;
//    -webkit-font-smoothing: antialiased;
//    display: inline-block;
//    font-style: normal;
//    font-variant: normal;
//    text-rendering: auto;
//    line-height: 1;
//    font-family: "Font Awesome 5 Free";
//    font-weight: 900;
//    font-size: 18pt;
//    margin-right: 6px;
//}
//
//[type='checkbox']:checked + label::before {
//    content: "\F14A";
//    color: green;
//}

//input[type="checkbox"] { position: absolute; opacity: 0; z-index: -1; }
//input[type="checkbox"]+span { font: 16pt sans-serif; color: #000; }
//input[type="checkbox"]+span:before { font: 16pt FontAwesome; content: '\00f096'; display: inline-block; width: 16pt; padding: 2px 0 0 3px; margin-right: 0.5em; }
//input[type="checkbox"]:checked+span:before { content: '\00f046'; }
//input[type="checkbox"]:focus+span:before { outline: 1px dotted #aaa; }
//input[type="checkbox"]:disabled+span { color: #999; }
//input[type="checkbox"]:not(:disabled)+span:hover:before { text-shadow: 0 1px 2px #77F; }

.indent-sm {
    text-indent: 2rem;
}

.indent-md {
    text-indent: 3rem;
}

.indent-lg {
    text-indent: 4rem;
}

.-indent-sm {
    text-indent: -2rem;
}

.-indent-md {
    text-indent: -3rem;
}

.-indent-lg {
    text-indent: -4rem;
}


.z-1 {
    z-index: 5;
}

.z-2 {
    z-index: 10;
}

.z-3 {
    z-index: 15;
}

.z-4 {
    z-index: 20;
}

.z-5 {
    z-index: 25;
}


.bg-qse-1 {
    background-color: rgb(177, 98, 198);
}

.text-qse-1 {
    color: #b162c6;
}

.vertical-sub {
    vertical-align: sub;
}

@media (min-width: 576px) {
    .text-sm-justify {
        text-align: justify !important;
    }
}

@media (min-width: 768px) {
    .text-md-justify {
        text-align: justify !important;
    }
}

@media (min-width: 992px) {
    .text-lg-justify {
        text-align: justify !important;
    }
}

@media (min-width: 1200px) {
    .text-xl-justify {
        text-align: justify !important;
    }
}

.menu {
    &.show, &.collapsed, &.collapsing {
        ul {

            @media (max-width: 992px) {
                background-color: $secondary-color !important;

                a {
                    color: white !important;

                    &:hover {
                        color: silver !important;
                    }
                }
            }
        }
    }

    @media (min-width: 992px) {
        li {
            transform: perspective(1px) translateZ(0);
            box-shadow: 0 0 1px transparent;
            position: relative;
            overflow: hidden;
            display: inline-block;
            vertical-align: middle;

            &:after {
                content: "";
                position: absolute;
                z-index: 10;
                left: 0;
                right: 100%;
                bottom: 0;
                background: white;
                height: 2px;
                transition-property: right;
                transition-duration: .3s;
            }

            &:hover {
                a {
                    color: white !important;
                }
                &:after {
                    right: 0 !important;
                }
            }
        }
    }
}

.pointer {
    cursor: pointer;
}

#sidebar-wrapper {
    margin-left: -15rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
    width: 15rem;
}

#page-content-wrapper {
    width: 100%;
    //min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}

@media (min-width: 768px) {
    #sidebar-wrapper {
        margin-left: 0;
    }

    #page-content-wrapper {
        min-width: 0;
        width: 100%;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -15rem;
    }
}

.opacity-0 {
    opacity: 0;
}
.opacity-30 {
    opacity: .3;
}
.opacity-50 {
    opacity: .5;
}
.opacity-70 {
    opacity: .7;
}
.opacity-100 {
    opacity: 1;
}

.show-list {
    h5 {
        font-weight: bold;
    }
}
